import React from "react";
import {Layout} from "../components/Layout";
import PageNav from "../components/PageNav";
import {BoardPageModel} from "../store/models/board-page";
import SEO from "../components/SEO";
import CtaDoubleCard from "../components/cta/CtaDoubleCard";
import * as styles from "../styles/pages/board.module.scss"
import BoardImageContainer from "../components/board/BoardImageContainer";
import StyledText, {TextStyles} from "../components/typography";
import useMediaQuery from "../helpers/use-media-query";
import StrapiClient, {StrapiResponse, StrapiSingleTypes} from "../strapi-client/strapi-client";
import {MenuModel} from "../store/models/menu";
import {GetServerDataProps, GetServerDataReturn} from "gatsby";

type BoardPageProps = {
    serverData: {
        menu: StrapiResponse,
        page: StrapiResponse
    }
}

const BoardPage = (props: BoardPageProps) => {
    const boardPageModel: BoardPageModel = BoardPageModel.fromJSON(props.serverData.page);
    const menuModel: MenuModel = MenuModel.fromJSON(props.serverData.menu);
    const showMobileTitles = useMediaQuery('(max-width: 1024px)');

    return (
        <>
            <SEO seo={boardPageModel.seo}></SEO>
            <Layout
                slotAboveFooter={<CtaDoubleCard ctaCard={boardPageModel.ctaCard}/>}
                menu={menuModel}
            >
                <PageNav title={boardPageModel.title} links={boardPageModel.links}/>
                {showMobileTitles && <StyledText variant={TextStyles.h2} className={styles.boardMembersTitle}>
                        {boardPageModel.boardTitle}
                    </StyledText>
                }
                <div className={styles.singleGrid}>
                    <BoardImageContainer
                        board={boardPageModel.board}
                        icon={boardPageModel.board?.icon}
                        isSingle
                    />
                </div>
                {showMobileTitles && <StyledText variant={TextStyles.h2} className={styles.boardMembersTitle}>
                    {boardPageModel.boardMembersTitle}
                    </StyledText>
                }
                <div className={styles.grid}>
                    {boardPageModel.boardList && boardPageModel.boardList.map((item, index) => (
                        <BoardImageContainer
                            key={index}
                            board={item}
                            icon={item.icon}
                        />
                    ))}
                </div>
            </Layout>
        </>
    )
}

export default BoardPage;

export async function getServerData(context: GetServerDataProps): GetServerDataReturn {
    try {
        const locale: string = context.pageContext.locale as string;
        const strapi = new StrapiClient(locale);
        const page = await strapi.getSingleType(StrapiSingleTypes.boardPage);
        const menu = await strapi.getSingleType(StrapiSingleTypes.menu);

        return {
            props: {
                page,
                menu
            },
        }
    } catch (error) {
        return {
            status: 500,
            headers: {},
            props: {}
        }
    }
}

