import React from "react";
import * as styles from "../../styles/components/board-image-container.module.scss"
import {ImageModel} from "../../store/models/image";
import StyledText, {TextStyles} from "../typography";
import {BoardModel} from "../../store/models/board-page";
import ProgressiveImage from "../common/ProgressiveImage";

type Props = {
    board?: BoardModel | null,
    icon?: ImageModel | null,
    isSingle?: boolean,
}

const BoardImageContainer = (props: Props) => {
    const {board, icon, isSingle} = props
    return (
        <div className={`${styles.container} ${isSingle ? styles.singleContainer : ''}`}>
            <img
                src={icon?.fullUrl}
                alt={icon?.alt}
                className={styles.icon}
            />
            {board?.image && <ProgressiveImage
              image={board?.image}
              className={styles.image}
            />}
            <div className={isSingle ? styles.singleGrid : ''}>
                <StyledText variant={isSingle ? TextStyles.h1 : TextStyles.h2} className={isSingle ? '' : styles.name}>
                    {board?.name}
                </StyledText>
                <StyledText variant={isSingle ? TextStyles.h2Regular : TextStyles.description} className={isSingle ? styles.singleDescription : styles.description}>
                    {board?.description}
                </StyledText>
            </div>
        </div>
    )
}

export default BoardImageContainer;
