// extracted by mini-css-extract-plugin
export var accentColor = "#e10600";
export var container = "board-image-container-module--container--d2de8";
export var description = "board-image-container-module--description--02e29";
export var dividerColor = "#e4e4e4";
export var dividerWhiteColor = "#fff";
export var icon = "board-image-container-module--icon--d471c";
export var image = "board-image-container-module--image--89878";
export var name = "board-image-container-module--name--0b523";
export var primaryColor = "#fff";
export var secondaryColor = "#000";
export var singleContainer = "board-image-container-module--singleContainer--089d0";
export var singleDescription = "board-image-container-module--singleDescription--96219";
export var singleGrid = "board-image-container-module--singleGrid--57569";
export var textDisabledColor = "#ccc";
export var textPrimaryColor = "#101820";
export var textSecondaryColor = "#fff";